import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Home from './Pages/Home';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Aboutus from './Pages/Aboutus';
import Mainfooter from './Components/Mainfooter';
import Services from './Pages/Services';
import Technologies from './Pages/Technologies';
import Contactus from './Pages/Contactus';
import Career from './Pages/Career';
import WebDevelopment from './Pages/WebDevelopment';
import Appdevlopment from './Pages/Appdevlopment';
import Softwaredevlopment from './Pages/Softwaredevlopment';
import Digitalmarket from './Pages/Digitalmarket';
import Uxdesign from './Pages/Uxdesign';

import Seooptimizee from './Pages/Seooptimizee';
import Projects from './Pages/Projects';
import Course from './Pages/Course';
import Privacypolicy from './Pages/Privacypolicy';
import TandC from './Pages/TandC';
import PrivacyNew from './Pages/PrivacyNew';
import TandCnew from './Pages/TandCnew';


function App() {
  return (

    <Router>

<Header/>
      <Routes>
        <Route path='*' element={<Home/>}/>
        <Route path='/' element={ <Home/>}/>
        <Route path='/about' element={<Aboutus/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/technologies' element={<Technologies/>} />
        <Route path='/contact' element={<Contactus/>} />
        <Route path='/career' element={<Career/>} />
        <Route path='/web-development' element={<WebDevelopment/>} />
        <Route path='/app-development' element={<Appdevlopment/>} />
        <Route path='/software-development' element={<Softwaredevlopment/>} />
        <Route path='/digital-marketing' element={<Digitalmarket/>} />
        <Route path='/ui-design' element={<Uxdesign/>} />
        <Route path='/seo-optimization' element={<Seooptimizee/>} />
        <Route path='/projects' element={<Projects/>} />
        <Route path='/course' element={<Course/>} />
        <Route path='/terms-and-conditions' element={<TandCnew/>} />
        <Route path='/privacy-policy' element={<PrivacyNew/>} />

      </Routes>

      <Mainfooter/>


    </Router>
    
  );
}

export default App;
