import React from 'react'
import Coursecard from '../Components/Coursecard'
import Coursecardmain from '../Components/Coursecardmain'
import Feuture from '../Components/Feuture'
import Pageheader from '../Components/Pageheader'
import TopRecuriting from '../Components/TopRecuriting'

const Course = () => {

    // const frontendData = {
    //     programname: 'Front End Development',
    //     skills: 'HTML,CSS,JAVASCRIPT,BOOTSTRAP,REACT',
    //     skillsicon: [
    //         'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/640px-HTML5_logo_and_wordmark.svg.png',
    //         'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png'
    //     ],
    //     programduration:'4 Months',
    //     enquiry:'+917898620456'
    // }
    return (
        <div>
            <Pageheader name='Courses' />

            <h3 style={{fontSize:30,color:'#2f78c2'}} className='heading'>Start your journey of success
Personalised Coding Programs to make coding easier for you</h3>

<h3 style={{fontSize:20,color:'#2f78c2'}} className='heading'>100% Job Placement Guaranteed courses by Gwalax with a Minimum Package of 3LPA</h3>
            
            {/* <Feuture heading={false} /> */}
            {/* <Coursecard data={frontendData}/> */}
            <Coursecardmain/>

            <TopRecuriting/>
        </div>
    )
}

export default Course