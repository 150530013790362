import { useMediaQuery } from '@mui/material';
import React from 'react'


const Softwaredevelopmm = () => {

    const matches = useMediaQuery('(max-width:600px)');

    return (
        <div>
            <div style={{ margin: '30px 15px' }}>

                <div style={{ fontSize: matches ? 15 : 18, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>The professionals at Gwalax are exceptional in converting an idea into the full-fledged workable and usable software solutions.  Our strategies and analysts are best in building and discussing the requirements with the clients to understand what they actually seeking to achieve through the software. Our experts are always active to deal with the entire project life cycle including project management, system analysis, and design, software application development, deployment, hosting, training, and maintenance..</p>


                    <img style={{ width: '95vw' }} src='img/software-development-banner.jpg' />

                    <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>We have been foremost in developing software such as web applications, desktop applications, e-commerce portals and websites as per the client specifications. Let us discuss all the software and application types one by one.</p>




                    <div style={{ textTransform: 'none' }}>

                        <h1 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Customized Web Applications</h1>

                        <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>We develop innumerable types of web applications for various domains such as Travel, transportation, health or fitness, education, etc. We are ready to work with almost all the existing technologies. Our only focus is to deliver a scalable solution that can maximize the return on investment (ROI) and improve the client business operations.</p>




                    </div>


                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>E-commerce Applications</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>We create static, dynamic and superlative e-commerce portals for our clients, our apps and software are readily integrated with social media apps and multiple payment gateways. Responsive websites and amazing designs are the add-on trending features that have become the necessary aspects of the applications over the last decade. We have come up with all such one-stop solutions which have made us a famed name in the world of technology. As far as the e-commerce facet is concerned, we deliver product listing, pricing, order fulfillment, shopping cart, deals, and promotion, etc. We assure you as you would find yourself comfortable with the best solutions to your business.</p>

                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Content Management System</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>We have reached a pinnacle in developing and delivering web content management solutions. Our experts bring you the best quality service and cover all the requirements from a customized CMS to a simple and user-friendly CMS for managing the self portal. Our CMS solutions consist of Dynamic template integration, Access control, content maintenance features, Rich media management Document, file management and many more.</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Migration Services</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Our end to end solutions bring more efficiency and help the companies in migrating the legacy system seamlessly to the newly established ones. As a result, it reduces the cycle times by bringing access to relevant information, improving scalability, reliability, and security. In our services, the migration process consists analysis of existing applications, planning and prioritizing activities of migration for developing and integrating the latest functionalities into the legacy system.</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>API Integration Services</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>We offer quality integration services of third-party applications. Our API integration service includes integrations with online advertising networks such as Google Adwards and Bing ads etc. With all such APIs, we provide payment gateway integrations like Instamojo, PayTM, Paypal, etc.</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>SharePoint Consulting</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>We are popular for diverse, flexible solutions and cover basic configuration and deployment of MOSS. Our custom solutions include the integration of a desperate system/BDC, dashboard reports, development of custom web parts, business intelligence, etc. You will be surely glad to get in touch with the services of best maintenance, superlative enhancement, and support via remote and onsite services</p>
                    </div>







                   




                </div>



            </div>

        </div>
    )
}


export default Softwaredevelopmm




