import React, { useState } from 'react'
import '../globle.css'
import Contmodal from './Contmodal'

const Header = () => {

    const [open, setopen] = useState(false)

    const [modal, setmodal] = useState(false)



    window.onscroll = () => {
        setopen(false)
    }
    const handleclk = ()=>{
        setopen(!open)
    }
    return (
        <header>

            <a href="/" className="logo"><img  className='logo' src='weblogo2.png'/></a>
            

            <div id="menu-bar" onClick={handleclk} className={open?"fas fa-times":"fas fa-bars"}></div>

            <nav  className={open?'navbar nav-toggle':'navbar mynavv'} >
                <a href="/">Home</a>
                <a href="/about">About us</a>
                <a href="/services">Service</a>
                <a href="/projects">Project</a>
                <a href="/contact">Contact</a>
                <span style={{position:'relative'}}>
                {/* <span class="blink binkng">NEW</span> */}
                <img className='binkng' style={{width:35,height:25}} src='https://static.wixstatic.com/media/be5dd5_fdeb1b362da8401e9fad9037dc80a9ea~mv2.gif'/>
                <a href="/course">Course</a>

                </span>
                
                <a style={{border:'none'}}><button onClick={()=>setmodal(true)} style={{cursor:'pointer',borderRadius:40,backgroundColor:'#186cdb',padding:10,color:'white',fontSize:18}}>GET A QUOTE</button></a>

                <Contmodal modal={modal} setmodal={setmodal}/>
                
            </nav>

            


        </header>
    )
}

export default Header