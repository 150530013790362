import { useMediaQuery } from '@mui/material';
import React from 'react'


const Digitalmm = () => {

    const matches = useMediaQuery('(max-width:600px)');

    return (
        <div>
            <div style={{ margin: '30px 15px' }}>

                <div style={{ fontSize: matches ? 15 : 18, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Digital marketing comprises of a number of activities for the web apart from marketing and each and every activity needs to be executed with focus on the end users to ensure success. Fortunately, we have an excellent team of digital marketing experts, who are working 24/7 to attract, engage and convert your customers into sales.</p>


                    <img style={{ width: '95vw' }} src='img/Digital-Marketing-Banner.jpg' />

                    <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>SEO, SEM, PPC, SMO, Content marketing and social media networking, etc. comprise a perfect digital marketing strategy. We are Google certified marketing professionals as well as bing ads accredited agency, and each member of our digital marketing team flaunts a great deal of experience and expertise in their field of marketing and are always on the hunt for new and innovative ideas to connect businesses to people across the globe.</p>

                   


                    <div style={{ textTransform: 'none' }}>

                        <h1 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Digital Marketing Strategy</h1>





                    </div>

                    <div style={{ margin:10 }}>

                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>SEO (Search Engine Optimization)</h3>

                        <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>We have a perfectly planned SEO strategy that will fit into your business requirements. Optimizing your site based on our effective keyword research analogy will drive your site to the top of the search engine results</p>

                    </div>


                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>SEM & PPC (Search Engine Marketing)</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>SEM is audience driven. Finding out who exactly your audience is and targeting them is what forms the major idea of our SEM strategy. We create compelling advertisements that will attract, engage and convert your customer.</p>

                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Social Media Marketing</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Connecting your business to the world We possess a great deal of expertise in social media networking and this is evident from the fact that we have been in the news for some of our researches on the latest trends reported in the world of social media. Enjoy the perks of connecting with your people through the most sought after business process over the web</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Conversion Rate Optimization</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>CRO is different from optimizing content as CRO is an art that combines years of experience with a unique approach and a combination of testing and data analysis to improve sales funnel and better ROI.</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Content Marketing</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>We have an excellent team of content marketing experts, who are constantly on the search for creating great content marketing strategies that will drive your people to your business.</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Email Marketing</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Email marketing is a business process that only an expert can execute successfully out of experience and skills. We have certain strategies that are tried-and-proven by many of our clients who are implementing email marketing to connect with their customers while enhancing their customer count.</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Online Reputation Management</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Reputation is a word that can be created only through trust. We create online reputation management campaigns that will generate trust through genuine methodologies that are tried-and-proven for most of the reputed business firms running successfully online.</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Analytics Consultation</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Gwalax is an expert at Analytics Consultation. Thanks to our team of professional experts who devise an excellent strategy for analyzing, adjusting and improvising business objective thereby helping you accomplish your business goals and achieve success.</p>
                    </div>

                   







                   




                </div>



            </div>

        </div>
    )
}




export default Digitalmm