import React from 'react'
import Footer from './Footer'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import { useMediaQuery } from '@mui/material';


const Mainfooter = () => {

    const matches = useMediaQuery('(max-width:600px)');
  return (
    <div className='numercard'  >
        <div className='numdivv' style={{fontSize:matches?'15px':'22px'}} >
            
            <div>
                <img style={{width:120}} src='weblogo2.png'/>
            </div>

            <div style={{display:'flex',flexDirection:matches?'column':'row',alignItems:'center'}}>
                <LocationOnOutlinedIcon sx={{fontSize:35,color:'#f2db07'}} />
                <p>63 Jalalpur Gwalior MP 474005</p>
            </div>

            <div style={{display:'flex',alignItems:'center',flexDirection:matches?'column':'row'}}>
                <ContactPhoneOutlinedIcon sx={{fontSize:35,color:'#f2db07',margin:'0px 15px'}}/>
                <div>
                <p>+917898620456</p>
                <p>sales@gwalax.in</p>
                </div>
            </div>

        </div>
        <Footer/>
    </div>
  )
}

export default Mainfooter