import React, { useRef, useState } from 'react'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Divider from '@mui/material/Divider';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import emailjs from '@emailjs/browser';
import { Alert, Snackbar } from '@mui/material';

const Enrollform = ({ sidebar }) => {

	const [open, setopen] = useState(false)
	const [open1, setopen1] = useState(false)
	const form = useRef();
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setopen(false);
		setopen1(false);
	};

	const [dta, setdta] = useState({
		fullname: '',
		emailid: '',
		phone: '',
		city:'',
        college:'',
        passout:'',
        course:''
	})

	const { fullname, emailid, phone, city,college,passout,course } = dta

	const handlefmchnage = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setdta({ ...dta, [name]: value })
	}



	const sendEmail = (e) => {
		e.preventDefault();


		if (fullname !== '' && emailid !== "" && phone !== '' && city !== "" && passout !=='' && course !=='') {


			if (phone.length == 10) {
				emailjs.sendForm('service_pf9pib6', 'template_jffdehk', form.current, 'UEQHDllkoS2pYPFow')
					.then((result) => {
						setopen(true);
					}, (error) => {
						setopen1(true);
					});

                

					setdta({fullname:'',emailid:'',city:'',phone:'',college:'',passout:'',course:''})
			} else {
				alert('Please Fill 10 Digit Mobile Number')
			}


		} else {
			alert('Please Fill All Data')
		}





	};

	return (
		<div className="container">
			<div className="">
				
				<div >
					<h2 style={{textAlign:'center'}}>Enroll Now</h2>
					<form ref={form} className="right" onSubmit={sendEmail}>
						<input type="text" className="field" value={dta.fullname} onChange={handlefmchnage} name='fullname' placeholder="Your Name" />
						<input type="email" className="field" value={dta.emailid} onChange={handlefmchnage} name='emailid' placeholder="Your Email" />
						<input type="number" className="field" value={dta.phone} onChange={handlefmchnage} name='phone' placeholder="Phone" />

                        <input type="text" className="field" value={dta.city} onChange={handlefmchnage} name='city' placeholder="City" />

                        <input type="text" className="field" value={dta.college} onChange={handlefmchnage} name='college' placeholder="College or School Name " />

                        

                        <div className="custom-select" style={{width:'100%'}}>
                        <select style={{marginBottom:20}} placeholder='Collage'  name='passout' value={dta.passout} onChange={handlefmchnage}>
                        <option value="" disabled selected hidden>Pass Out Year</option>
                            <option value='2010'>2010</option>
                            <option value='2011'>2011</option>
                            <option value='2012'>2012</option>
                            <option value='2013'>2013</option>
                            <option value='2014'>2014</option>
                            <option value='2015'>2015</option>
                            <option value='2016'>2016</option>
                            <option value='2017'>2017</option>
                            <option value='2018'>2018</option>
                            <option value='2019'>2019</option>
                            <option value='2020'>2020</option>
                            <option value='2021'>2021</option>
                            <option value='2022'>2022</option>
                            <option value='2023'>2023</option>
                            <option value='2024'>2024</option>
                            <option value='2025'>2025</option>
                            <option value='2026'>2026</option>
                            <option value='2027'>2027</option>
                            <option value='2028'>2028</option>
                            <option value='2029'>2029</option>
                            <option value='2030'>2030</option>
                           
                            
                        </select>


                        <select style={{marginBottom:20}}  name='course' value={dta.course} onChange={handlefmchnage}>
                        <option value="" disabled selected hidden>Select Course</option>
                            <option value='Front end Development'>Front end Development</option>
                            <option value='Full Stack Development'>Full Stack Development</option>
                         
                           
                            
                        </select>

                        </div>


						





						<button style={{width:'100%',height:35,fontSize:14,fontWeight:'700'}} className='btn10'>ENROLL NOW</button>

						{/* <input className='btn10' type='button' value='Send'/> */}
					</form>
				</div>
			</div>

			<Snackbar open={open} sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success" sx={{ width: '100%', fontSize: 20 }}>
                Student Enrolled Successfully Our team will connect you soon...
				</Alert>
			</Snackbar>

			<Snackbar open={open1} sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error" sx={{ width: '100%', fontSize: 20 }}>
                Student Enrolled Not Successfully some Technical Issue! Please Try Now
				</Alert>
			</Snackbar>
		</div>



	)
}

export default Enrollform