import React from 'react'
import Aboutcard from '../Components/Aboutcard'
import Aboutpage from '../Components/Aboutpage'
import Pageheader from '../Components/Pageheader'

const Aboutus = () => {

    const arrmy = [
        "GWALAX ONLINE SERVICES is a specialized web development company based out at Gwalior MP, India. We provide high quality and professional software services to the clients residing across the globe. Our professionals have been working with clients of India as well as from International origin. Our team consists of talented and dedicated professional consultants who work hard and dedicatedly to understand your business needs. We are the best in providing you the valuable insight to leverage your internet presence as well as business prospects.",

        "Over the past years, we have been exceptional in delivering the quality product to our clients. We are not limited in terms of area rather we deliver the quality service across the globe. We are powered with the best latest technology & synergistic approach for online solutions.",

        "Our highly competitive rates enable your business website to dramatically improve sales and rankings while keeping your budget intact. Work with us to believe it.",

        "Our motive is not only to deliver software and services but we strive for never ending relationships with our clients.",

        "Our professionals are strategic thinkers with a strong work ethic and extremely dedicated to their work. This allows us to maintain our satisfaction guarantee policy and be proud of the tremendous reputation that we have established among our existing clients."
    ]
  return (
    <div>
        <Pageheader name='About us'/>
        <Aboutpage data={arrmy}/>
        
    </div>
  )
}

export default Aboutus