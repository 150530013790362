import { useMediaQuery } from '@mui/material';
import React from 'react'


const Uxdesignmm = () => {

    const matches = useMediaQuery('(max-width:600px)');

    return (
        <div>
            <div style={{ margin: '30px 15px' }}>

                <div style={{ fontSize: matches ? 15 : 18, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Intuitive designs combined with compelling user experience are what makes our apps stand ahead in the digital age. Seamless experience driven by international standards in collaboration with out-of-the-box ideas is the specialty of Acodez’s apps helping your business accomplish its goals.</p>


                    <img style={{ width: '95vw',height:matches?'30vh':'60vh' }} src='img/ui-ux-design-.png' />

                   

                   


                    <div style={{ textTransform: 'none' }}>

                        <h1 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>UX Design</h1>

                        <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Any part of a design that affects the user is UX design. At Acodez, we take into accountability each and every minutest aspect of this design variant to provide compelling and intuitive user experience. We offer mobile UX design, UX design for SAAS, Product UX design for startups and more, while ensuring that the end user enjoys a great value-added experience.</p>





                    </div>

                    <div style={{ margin:10 }}>

                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Interaction Design</h3>

                        <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>UX and UI are the two most important aspects of any design process. One drives the other and as they say UI is incomplete without UX and vice-versa. We offer beautiful interaction processes that are driven by the most modern technologies to offer a great interactive experience for your users.</p>

                    </div>


                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Information Architecture</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Perfectionizing Information Architecture happens only when it is in the hands of experts. We plan a proper information architecture, which gives birth to digital products that are bound to change the world. Collaborating new technologies, design methods and business processes to formulate digital products that mark a new beginning.</p>

                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Mobile UX</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Designing pleasurable Mobile UX experiences for people A great mobile UX is the key to your customer’s heart. We define and design mobile UX strategies that create excellent B2B and B2C functional enterprise mobile apps, which fall in the category of generation next app experiences.</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>UX Testing</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>All user experience processes housed under one roof Acodez IT Solutions is a one-stop solution for all your UX design needs. We are a warehouse of UX design services, starting from conceptualizing, analyzing, researching, prototyping, designing to the final stage of UX testing all under one roof.</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>UX Research</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Research is the first step to great design experiences We know that our clients have done their homework well. But, we have our own strategies before we start the UX design process. Unless our team of research analysts brainstorm and integrate their ideas from the UX research that they have done, we believe our UX design process is incomplete.</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Wireframes</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Wireframes are the soul of any website designs At Acodez, we work hand-in-hand with our clients. The finalization of your design happens only when you have gone through the design wireframes, which is the skeleton of your real-working model. Once you approve the wireframe, we are ready to go for the real one.</p>
                    </div>

                    

                   
                   







                   




                </div>



            </div>

        </div>
    )
}






export default Uxdesignmm