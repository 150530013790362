import { useMediaQuery } from '@mui/material'
import React from 'react'


const Careerpage = () => {
    const matches = useMediaQuery('(max-width:600px)');
  return (
    <div>

<p style={{margin:matches?'60px 5px':'80px 60px',color:'#142b5f',textTransform:'none',fontSize:18}}>currently no positions available future will be available</p>
    </div>
  )
}

export default Careerpage