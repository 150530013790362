import React from 'react'

const TopRecuriting = () => {

    const companyimg = [
        'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Tata_Consultancy_Services_Logo.svg/2560px-Tata_Consultancy_Services_Logo.svg.png',
        'https://www.wipro.com/content/dam/nexus/en/wipro-logo-new-og-502x263.jpg',
        'https://antino-web.s3.ap-south-1.amazonaws.com/images/images/Antinologo2021darkfinal.png',
        'https://www.accenture.com/t20180820T081710Z__w__/ie-en/_acnmedia/Accenture/Dev/Redesign/Acc_Logo_Black_Purple_RGB.PNG',
        'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Paytm_Logo_%28standalone%29.svg/1200px-Paytm_Logo_%28standalone%29.svg.png',
        'https://stockarea.io/blogs/wp-content/uploads/2021/11/flipkart-logo-39906.png',
        'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Byju%27s_logo.svg/1200px-Byju%27s_logo.svg.png',
        'https://www.fabhotels.com/blog/wp-content/uploads/2016/07/FAB_logo_large.png',
        'https://nextpax.com/wp-content/uploads/2022/01/makemytrip-1.png',
        'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Genpact_logo.svg/220px-Genpact_logo.svg.png',
        'https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/IBM_logo.svg/2560px-IBM_logo.svg.png',
        'https://1000logos.net/wp-content/uploads/2021/09/Cognizant-Logo.png',
        'https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Infosys_logo.svg/1280px-Infosys_logo.svg.png',
        'https://www.capgemini.com/wp-content/uploads/2022/05/Capgemini.gif',
        'https://files.techmahindra.com/static/img/brandkit/logo/Logo-True-Colors-original.png',
        'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Deloitte.svg/2560px-Deloitte.svg.png',
    ]
  return (
    <div>

<h1 style={{fontSize:18,fontWeight:'500'}} className="heading">Companies that hire for the roles</h1>

    

<div style={{margin:'30px 0px'}} className="box-container">

    <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',margin:'30px 2px'}} className=''>

        {companyimg.map((val)=>(
            <img className='companyimg' src={val} />
        ))}

        

    </div>

    <h3 style={{fontSize:19}} className='heading'>Over 100+ Hiring Partners</h3>

   

    


    

</div>
    </div>
  )
}

export default TopRecuriting