import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Avatar from '@mui/material/Avatar';
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";


const PreviousBtn = (props) => {
    
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowBackIos style={{ color: "#4372b0", fontSize: "35px", }} />
      </div>
    );
  };
  const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowForwardIos style={{ color: "#4372b0", fontSize: "35px" }} />
      </div>
    );
  };

const Loveusecard = () => {

    const Revidata = [
        {name:'Akash Singh ',img:'https://media-exp1.licdn.com/dms/image/C5603AQGLlIqfQlQtEw/profile-displayphoto-shrink_200_200/0/1603864821995?e=1659571200&v=beta&t=WP8zUId-4qKYbX-ccCULn1OS9n1_ogWDHstbGaedpis',dess:'The most supportive business partner I have come across till date. The work done is as per requiremnt and best is within timeline. I definitely feel you guys are a value add to my business. ',company:'observe.ai',postion:'Co-founder and CTO'},

        {name:'Dr K.S. BHATIA',img:'https://media-exp1.licdn.com/dms/image/C4D03AQF5fXJpupwpwA/profile-displayphoto-shrink_200_200/0/1612715494965?e=1659571200&v=beta&t=0UtMDTHkG4G_-SBAuHWuaz_-PFRCLWs3luHQEpIj3w0',dess:'Passionate experts and the creative approach is the trademark of GWALAX. They are perfect in keeping to agreed targets and requirements. I would not have any concern in recommending Gwalax for better and advanced outcomes.',company:'Pumpkart.com',postion:'Founder & CEO '},

        {name:'Sandeep Kumar',img:'https://media-exp1.licdn.com/dms/image/C4D03AQEE_v9I3UoK5w/profile-displayphoto-shrink_200_200/0/1621800410406?e=1659571200&v=beta&t=eiAXw6H6Rfwpyl--hhRWbNNdhL2QANDHhPK3L74VuIQ',dess:'I have found the experts of GWALAX highly professional to work with. They are extremely serious towards the deadline and always deliver the projects on time. I will prefer to get back to them in the future.',company:'alyli.com',postion:'Founder & CEO '},

        {name:'Sam Sudipta',img:'https://media-exp1.licdn.com/dms/image/C5603AQH2SBBxmJn0TA/profile-displayphoto-shrink_200_200/0/1516320407053?e=1659571200&v=beta&t=JBKQOtXFq7RG2f4zIyNoVnhlmvAMJP_bx8HEuUqV8Dk',dess:'We use GWALAX service to build an word-press template and they were very fast and shows a great communication skills and professionalism , i will work with them in future project for sure.',company:'alyli.com',postion:'Founder & CEO '},

        {name:'HEMANT SINGH ',img:'https://media-exp1.licdn.com/dms/image/C4D03AQGhAOqQW-UCqA/profile-displayphoto-shrink_200_200/0/1516838565473?e=1659571200&v=beta&t=g0ox8baXE__g0xlQKth5yoA15JuTpolT9RQu-C3Zn-s',dess:"I had engaged M/s Ahom Technologies Pvt Ltd to develop my company's website and I was delighted by the quality of work, as well as, their approach towards delivering Customer Delight The team which worked on my website, led by Shipra Anand, was technically sound and very creative and proactive in its approach. Working with this team was a wonderful experience because every time I provided them inputs, they came up with options which met, if not, exceeded my expectations.  I strongly recommend M/s Ahom Technologies for website development assignments.",company:'alyli.com',postion:'Managing Partner '},
    ]


    return (
        <div
      className="testimonial"
      style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
    >
      <div style={{ width: "50%", textAlign: "center" }}>
       
        <Slider prevArrow={<PreviousBtn  />} nextArrow={<NextBtn />} dots>
          {Revidata.map((val,i)=>(<Card key={i} img={val.img} name={val.name} feedback={val.dess} postion={val.postion}/>))}
        </Slider>
      </div>
    </div>
    )
}

const Card = ({ img ,name,feedback,postion}) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          color: "gray",
        }}
      >
        <Avatar
          imgProps={{ style: { borderRadius: "50%" } }}
          src={img}
          style={{
            width: 120,
            height: 120,
            border: "1px solid lightgray",
            padding: 7,
            marginBottom: 20,
          }}
        />
        <p>
          {feedback}
        </p>
        <p style={{ fontStyle: "italic", marginTop: 25 }}>
          <span style={{ fontWeight: 500, color: "green" }}>{name}</span> ,
          {postion}
        </p>
      </div>
    );
  };

export default Loveusecard