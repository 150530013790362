import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';


const Homecom = () => {


  const matches = useMediaQuery('(max-width:500px)');
    
  return (
    <section className="home" id="home">

    <div className="content" style={{width:'70%'}}>
        <h3 style={{fontSize:matches? 25:45,textTransform:'capitalize'}}>Become a developer with 100% Job Guarantee</h3>
        <p>Become a Full Stack Web Development Training Programme in 6-months, We guarantee you a min ₹3 LPA Job. Personalised Coding Programs to make coding easier for you to acheive your dream jobs. </p>
        <a href="/course" className="btn">ENROLL NOW</a>
    </div>

    <div className="image">
        <img  src='https://content.kaspersky-labs.com/se/coin/content/en-in/images/baseline/masthead-lrc/masthead-lrc-b2b-desktop/masthead-lrc-b2b-desktop.png'  alt=""/>
        
        <img src=''/>
    </div>

</section>
  )
}

export default Homecom