import { useMediaQuery } from '@mui/material';
import React from 'react'


const Seooptimm = () => {

    const matches = useMediaQuery('(max-width:600px)');

    return (
        <div>
            <div style={{ margin: '30px 15px' }}>

                <div style={{ fontSize: matches ? 15 : 18, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>An online search query triggers millions of results every time and competing for the top position in a search result to drive in traffic to your site can be difficult, without an expert team. We, at Acodez, forge a perfectly planned, fail-proof SEO strategy that will let your website be found on the top search results with organic leads flowing into your inbox.</p>


                    <img style={{ width: '95vw' }} src='img/seo-banner.jpg' />

                    <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>It is not merely our industry expertise that makes us a reliable partner for decades, but our technical skills and know-how of what works and what doesn’t. We have strong talented professionals who build a perfect framework to convert traffic from search engines. The experience gained helped us to create a dynamic framework that can be altered based on the changes in industrial technologies, algorithm shifts and client campaign data.</p>

                   


                    <div style={{ textTransform: 'none' }}>

                        <h1 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Search Engine Optimization</h1>





                    </div>

                    <div style={{ margin:10 }}>

                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Stage 1: Learning</h3>

                        <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>It is all about our understanding your brand as it is unique. We try to gather more information about your business, competitors, customers and industry to bring every possible success that drive growth. Thus we invest our learning time in discovery process, analytics audit, industry landscaping, technical website audit, competitive research.</p>

                    </div>


                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Stage 2: Planning</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>It is all about our understanding your brand as it is unique. We try to gather more information about your business, competitors, customers and industry to bring every possible success that drive growth. Thus we invest our planning time in keyword opportunity mapping, audience persona creation, competitive links audit, link opportunity mapping, topical research for content, link calendar creation and content calendar development.</p>

                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Stage 3: Adjustment</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>It is all about our understanding your brand as it is unique. We try to gather more information about your business, competitors, customers and industry to bring every possible success that drive growth. Thus we invest our adjustment time in executing technical updates, implementing analytics updates, launching content marketing campaign, creating personas for outreach.</p>
                    </div>

                    <div style={{margin:10}}>
                        <h3 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Stage 4: Growth</h3>
                        <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>It is all about our understanding your brand as it is unique. We try to gather more information about your business, competitors, customers and industry to bring every possible success that drive growth. Thus we invest our growth time in organic content creation, skyscraper content outreach, ongoing link outreach, link building campaigns, reporting, analysis, consulting.</p>
                    </div>

                   
                   







                   




                </div>



            </div>

        </div>
    )
}






export default Seooptimm