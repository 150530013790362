import React from 'react'

const Aboutcard = ({data}) => {
  return (
    <div className="footer" style={{background:'none'}}>

    <div className="box-container" style={{backgroundColor:'#205abd',borderRadius:10}}>

{data.map((val,i)=>(
    <div key={i} className="box1" style={{padding:20}}>
    <h3 style={{color:'#ff722f'}}>{val.name}</h3>
    <p style={{textTransform:'none'}}>{val.desc}</p>
</div>
))}
        

        

    </div>

    

</div>
  )
}

export default Aboutcard