import React from 'react'
import Digitalmm from '../Components/Digitalmm'
import Pageheader from '../Components/Pageheader'

const Digitalmarket = () => {
  return (
    <div>
        <Pageheader name='Digital Marketing'/>
        <Digitalmm/>
    </div>
  )
}

export default Digitalmarket