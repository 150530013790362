
import React from 'react'
import Contactcardd from '../Components/Contactcardd'
import Contactuspage from '../Components/Contactuspage'
import Pageheader from '../Components/Pageheader'

const Contactus = () => {
  return (
    <div>
        <Pageheader name='Contact Us'/>
        <Contactuspage/>
        
    </div>
  )
}

export default Contactus