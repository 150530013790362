import React from 'react'
import Archivment from '../Components/Archivment'
import Contact from '../Components/Contact'
import Feuture from '../Components/Feuture'
import Footer from '../Components/Footer'
import Homecom from '../Components/Homecom'
import Loveusers from '../Components/Loveusers'
import Maincontact from '../Components/Maincontact'
import Mainfooter from '../Components/Mainfooter'
import OurProjects from '../Components/OurProjects'
import UseTechnologies from '../Components/UseTechnologies'
import Workflow from '../Components/Workflow'

const Home = () => {
  return (
    <div>
        <Homecom/>
        <Feuture heading={true}/>
        <UseTechnologies heading={true}/>
        <Workflow/>
        <OurProjects heading={true} size={9}/>
        <Archivment/>
        <Loveusers/>
        <Maincontact heading={true} sidebar={true}/>
        
    </div>
  )
}

export default Home