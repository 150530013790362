import React from 'react'
import Pageheader from '../Components/Pageheader'
import Softwaredevelopmm from '../Components/Softwaredevelopmm'

const Softwaredevlopment = () => {
  return (
    <div>
        <Pageheader name='Software Development'/>
        <Softwaredevelopmm/>
    </div>
  )
}

export default Softwaredevlopment