import React from 'react'
import Projectcard from './Projectcard'

const OurProjects = ({heading,size}) => {

    const ourproject = [
        {id:1,projectname:'Car Sell & Buy',date:'01/08/2021',projectimg:'cardemo.jpg',url:'https://cardemogwalax.netlify.app/'},

        {id:2,projectname:'Ecommerce Website',date:'25/08/2021',projectimg:'ecomm.jpg',url:'https://ecommercedemogwalax.netlify.app/'},

        {id:3,projectname:'School Website',date:'06/11/2021',projectimg:'edu2.jpg',url:'https://educationdemogwalax.netlify.app/'},

        {id:4,projectname:'Online Food Order',date:'10/12/2021',projectimg:'food1.jpg',url:'https://fooddemogwalax.netlify.app/'},

        {id:5,projectname:'School Website',date:'28/01/2022',projectimg:'edu1.jpg',url:'https://schoolwebdemogwalax.netlify.app/'},

        {id:6,projectname:'Grocery Order Website',date:'25/02/2022',projectimg:'grocery.jpg',url:'https://grocerydemogwalax.netlify.app/'},

        {id:7,projectname:'Wedding Planer Website',date:'01/03/2022',projectimg:'wedding.jpg',url:'https://weddingdemogwalax.netlify.app/'},

        {id:8,projectname:'Real Estate',date:'01/03/2022',projectimg:'rent.jpg',url:'https://realestatedemogwalax.netlify.app/'},

        {id:9,projectname:'School Website',date:'01/03/2022',projectimg:'school2.jpg',url:'https://school2demogwalax.netlify.app/'},

        {id:10,projectname:'School Website',date:'01/03/2022',projectimg:'school1.jpg',url:'https://school3demogwalax.netlify.app/'},

        {id:11,projectname:'Construction Website',date:'12/03/2022',projectimg:'contr.jpg',url:'https://constructiondemogwalax.netlify.app/'},
        
        {id:12,projectname:'Travel Agency Website',date:'18/03/2022',projectimg:'travel.jpg',url:'https://travelagencydemogwalax.netlify.app/'},
        
    ]

    const newfill = ourproject.filter((val)=>val.id<=size)



  return (

    <section style={{margin:'30px 0px'}} className="project" id="project">

    {heading?<h1 className="heading">our Previous projects</h1>:null}

    <div className="box-container">
        {size?newfill.map((val,i)=>(
            <Projectcard key={i} data={val}/>
        )):ourproject.map((val,i)=>(
            <Projectcard key={i} data={val}/>
        ))}
        {heading?<a href='/projects' style={{width:100,textAlign:'center'}} className='btn10'>Show More</a>:null}
        
    </div>

    </section>


 
  )
}

export default OurProjects