import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Maincontact from './Maincontact';
import { useMediaQuery } from '@mui/material';
import Contact from './Contact';



const Contmodal = ({modal,setmodal}) => {

    const matches = useMediaQuery('(max-width:600px)');

    
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: matches?300:600,
   
    bgcolor: 'background.paper',
   borderRadius:10,
    boxShadow: 24,
    p: 0,
  };

 

  return (
    <div>
      
      <Modal
        open={modal}
        onClose={()=>setmodal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
        <Contact sidebar={false}/>
        </Box>
      </Modal>
    </div>
  )
}

export default Contmodal