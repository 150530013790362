import React from 'react'
import Pageheader from '../Components/Pageheader'
import TandC from './TandC'

const TandCnew = () => {
  return (
    <div>
        <Pageheader name='Terms & Conditions'/>
        <TandC/>
    </div>
  )
}

export default TandCnew