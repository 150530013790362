import React from 'react'
import Careerpage from '../Components/Careerpage'
import Pageheader from '../Components/Pageheader'

const Career = () => {
  return (
    <div>
        <Pageheader name='Career'/>
        <Careerpage/>
    </div>
  )
}

export default Career