import React from 'react'
import OurProjects from '../Components/OurProjects'
import Pageheader from '../Components/Pageheader'

const Projects = () => {
  return (
    <div>
        <Pageheader name='our Projects'/>
        <OurProjects/>
    </div>
  )
}

export default Projects