import React from 'react'
import Pageheader from '../Components/Pageheader'
import Privacypolicy from './Privacypolicy'

const PrivacyNew = () => {
  return (
    <div>
        <Pageheader name='Privacy Policy'/>
        <Privacypolicy/>
    </div>
  )
}

export default PrivacyNew