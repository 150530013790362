import { useMediaQuery } from '@mui/material';
import React from 'react'


const Appdevlopmm = () => {

    const matches = useMediaQuery('(max-width:600px)');

    return (
        <div>
            <div style={{ margin: '30px 15px' }}>

                <div style={{ fontSize: matches ? 15 : 18, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>We are surrounded by a number of technologies that are rapidly growing and collecting fame across the globe. Among these technologies, there is an important entity named as Android. It is one of the fastest growing mobile operating systems with 60-80 percent user adoption rates It is constantly updating and innovating the mobile operating system to increase user engagement with the smooth mobile operation. It can easily run across tablets, smartwatches, TV, augmented reality and the automobiles. If you want to create Android App for your business, always go with the best choice.</p>


                    <img style={{ width: '95vw' }} src='img/Mobile-App-Development-Banner-Final.png' />




                    <div style={{ textTransform: 'none' }}>

                        <h2 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Our Android Services</h2>

                        <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Gwalax is a preferred global Android development company in India that offers scalable, cloud read and intuitive apps. We follow agile methodologies, implements android app development best practices with enterprise level development. Our applications are catered to the client needs with a broad range of features to increase the revenue through mobile ready business. Some of our android services are:</p>

                        {/* <ul style={{listStyleType:'none',margin:'40px 20px'}}>
                       <li style={{margin:10,}}>Gather the requirements of the business- ‘What is the Web site going to achieve’.</li>
                        <li style={{margin:10,}}>Draft a plan of implementation for the Site and choose a platform.</li>
                        <li style={{margin:10,}}>Have a separate graphic artist, create the wireframes of the Site.</li>
                        <li style={{margin:10,}}>Have a front-end engineer convert the graphics into HTML5, JavaScript and CSS Code</li>
                        <li style={{margin:10,}}> Have a software engineer to develop any custom components or business logic modules.</li>
                        <li style={{margin:10,}}> Perform a performance analysis to determine the maximum capacity of the Web site amongst the exposed users.</li>
                        <li style={{margin:10,}}> Continuously monitor the site performance and compare them to business metrics with the ongoing environment.</li>

                       </ul> */}

                        <ul style={{ margin: 20 }}>
                            <li style={{ margin: 10, color: '#142b5f' }}>Custom Android app development.</li>
                            <li style={{ margin: 10, color: '#142b5f' }}>Mobile Enablement.</li>
                            <li style={{ margin: 10, color: '#142b5f' }}>Android UX and design.</li>
                            <li style={{ margin: 10, color: '#142b5f' }}>Android widget development.</li>
                            <li style={{ margin: 10, color: '#142b5f' }}>Android apps testing.</li>
                            <li style={{ margin: 10, color: '#142b5f' }}>Android support and Maintenance.</li>
                            <li style={{ margin: 10, color: '#142b5f' }}>Android apps portability.</li>
                            <li style={{ margin: 10, color: '#142b5f' }}>Android Consulting.</li>

                        </ul>

                    </div>



                    <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>We offer the best service for Android application development solution to create unique value with respect to the business. Our skilled android application developers have in-depth knowledge of the Android mobile platform and strong experience in the Android software development kit. We are the best in building interactive apps that can support a diversity of devices such as tablets, smart-watches, TV, and auto.</p>

                    <h2 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Advantages Of Android Development</h2>



                    <p style={{ margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>There are several advantages of using Android application development services which is as follows:</p>

                    <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}></p>


                    <div>

                        <h5 style={{ margin: matches ? '20px 0px' : '20px 60px', color: '#142b5f', textTransform: 'none' }}>Open Source</h5>
                        <p style={{ fontSize: matches ? 12 : 15, margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Android community offers open source facility from licensing, royalty-free, and the best technology framework. The architecture of Android SDK is totally open so that one can easily interact with the community for the upcoming expansions of android mobile application development. As a result, this makes android so attractive for handset manufacturers and wireless operators.</p>

                        <h5 style={{ margin: matches ? '20px 0px' : '20px 60px', color: '#142b5f', textTransform: 'none' }}>Easy Integration</h5>
                        <p style={{ fontSize: matches ? 12 : 15, margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>An Android app can be a suitable solution for those who are seeking for quality features of technical customization and integration of a web application. With Android, we can integrate and tweak the mobile app according to the business need. It enables the background processes in helping you to integrate the applications.</p>

                        <h5 style={{ margin: matches ? '20px 0px' : '20px 60px', color: '#142b5f', textTransform: 'none' }}>Multiple Sales Channels</h5>
                        <p style={{ fontSize: matches ? 12 : 15, margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Android app development services can be deployed in different ways based on the requirements. We do not have to rely on a single market to distribute the applications. Even one can use a third party application market place with the personal distribution and sales channel. With the proper and selective use of promotional strategy, one can easily reach to users via different channels.</p>

                        <h5 style={{ margin: matches ? '20px 0px' : '20px 60px', color: '#142b5f', textTransform: 'none' }}>Easy Adoption</h5>
                        <p style={{ fontSize: matches ? 12 : 15, margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>Android applications have been scripted in Java language with a large number of libraries. Just by the help of Java concepts, you can have all the benefits because it is so easy to adopt or script the code. Even Java developers can have a transition of the code script into a mobile application with the implementation of android application development services in the app.</p>

                        <h5 style={{ margin: matches ? '20px 0px' : '20px 60px', color: '#142b5f', textTransform: 'none' }}>High ROI and low investment</h5>
                        <p style={{ fontSize: matches ? 12 : 15, margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>When compared to other platforms android has a low barrier to entry. It offers software development kit (SDK) free of cost to the developer community which diminishes the development and the cost of licensing. The cost of development can be divided into three categories which are as follows.

                            <li>Application development.</li>
                            <li>Testing.</li>
                            <li>Investing in testing or deploying the android mobile application.</li>
                        </p>



                    </div>

                    <h2 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Why Choose Us</h2>
                    <p style={{ fontSize: matches ? 15 : 18, margin: matches ? '10px 0px' : '10px 60px', color: '#142b5f', textTransform: 'none' }}>

<li>We have a great track record of delivering high speed and super performing Android projects.</li>
<li>We take quality and security very seriously.</li>
<li>The solutions delivered by us are synonymous with secured solutions.</li>
<li>Our team adheres to the best practices of android development.</li>
<li>Best in Time Delivery.</li>
<li>World class maintenance and support services after delivering the final project.</li>
</p>
                    




                </div>



            </div>

        </div>
    )
}

export default Appdevlopmm