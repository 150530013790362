import React from 'react'
import Pageheader from '../Components/Pageheader'
import Seooptimm from '../Components/Seooptimm'

const Seooptimizee = () => {
  return (
    <div>
        <Pageheader name='SEO Optimization'/>
        <Seooptimm/>
    </div>
  )
}

export default Seooptimizee