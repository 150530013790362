import { useMediaQuery } from '@mui/material'
import React from 'react'


const Contactcardd = ({icon,name}) => {
    const matches = useMediaQuery('(max-width:600px)');
    return (
        
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <div className='border-hover' >

                    <div className="border-hover-bg">
                        <div className="icon-custom-contact">

                            <i className={icon} aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <div className='cardfontpp'>
                <p>{name}</p>
                </div>
            </div>

            



     
    )
}

export default Contactcardd