import { useMediaQuery } from '@mui/material'
import React from 'react'


const Projectcard = ({ data }) => {
  const handleclk = ()=>{
    window.open(data.url)
  }

  return (
    <div className="box" onClick={handleclk}>
      <span className="number">{data.id < 10 ? ` 0${data.id}` : data.id}</span>
      <img src={data?.projectimg} alt="" />
      <div className="info">
        <h3>{data.projectname}</h3>
        <p>{data.date}</p>
      </div>
    </div>
  )
}

export default Projectcard