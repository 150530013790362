import { useMediaQuery } from '@mui/material'
import React from 'react'
import Aboutcard from './Aboutcard';


const Aboutpage = ({data}) => {

    const arrmy = [
        {name:'Commitment',desc:'We strive hard to supersede our client’s expectations and ensure that their online goals are met successfully. Our commitment towards our clients has earned us an impressive portfolio & reference group covering a variety of industries all over the world.'},
        {name:'Quality',desc:'We always strive for delivering the best to our clients within a specified budget while maintaining the highest quality standards.'},
        {name:'Values',desc:'Being a leading web development agency in India, we at GWALAX believe in high values that express our mission, commitment, and the actions. When it comes to deal with the customer’s satisfaction, there is no one who can stand parallel to us.'},
    ]

    const matches = useMediaQuery('(max-width:600px)');
  return (
    <div style={{margin:'30px 15px'}}>

        <div style={{fontSize:18}}>
            {data.map((val,i)=>(
                <p key={i} style={{margin:matches?'30px 0px':'30px 60px',color:'#142b5f',textTransform:'none'}}>{val}</p>
            ))}
            
        </div>

        <Aboutcard data={arrmy}/>

    </div>
  )
}

export default Aboutpage