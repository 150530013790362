import { useMediaQuery } from '@mui/material';
import React from 'react'


const Webdevlopmm = ({ data }) => {

    const matches = useMediaQuery('(max-width:600px)');

    return (
        <div>
            <div style={{ margin: '30px 15px' }}>

                <div style={{ fontSize: matches?15:18,display:'flex',flexDirection:'column',alignItems:'center' }}>
                    
                        <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Gwalax is a leading web development company based out in Gwalior MP, India. Over the past years, we have offered all kinds of custom build websites, web portals, and web applications. We use cutting edge technologies to transform the ideas into reality.</p>


                        <img style={{width:'95vw'}} src='img/Web_Developmen_t.jpg'/>

                        <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>We offer extreme quality service almost in all the existing platforms such as Magento Web Development, Shopify development, Drupal Web Development, etc.</p>


                        <div style={{textTransform:'none'}}>

                        <h2 style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Our comprehensive Website design & Development process is composed of the following:</h2>

                       <ul style={{listStyleType:'none',margin:'40px 20px'}}>
                       <li style={{margin:10,}}>Gather the requirements of the business- ‘What is the Web site going to achieve’.</li>
                        <li style={{margin:10,}}>Draft a plan of implementation for the Site and choose a platform.</li>
                        <li style={{margin:10,}}>Have a separate graphic artist, create the wireframes of the Site.</li>
                        <li style={{margin:10,}}>Have a front-end engineer convert the graphics into HTML5, JavaScript and CSS Code</li>
                        <li style={{margin:10,}}> Have a software engineer to develop any custom components or business logic modules.</li>
                        <li style={{margin:10,}}> Perform a performance analysis to determine the maximum capacity of the Web site amongst the exposed users.</li>
                        <li style={{margin:10,}}> Continuously monitor the site performance and compare them to business metrics with the ongoing environment.</li>

                       </ul>

                       <ul style={{margin:10}}>
                       <li style={{margin:10,color:'#142b5f'}}>PHP Development</li>
                       <li style={{margin:10,color:'#142b5f'}}>Magento Development</li>
                       <li style={{margin:10,color:'#142b5f'}}>Drupal Development</li>
                       <li style={{margin:10,color:'#142b5f'}}>Shopify Development</li>
                       <li style={{margin:10,color:'#142b5f'}}>WordPress Development</li>
                       <li style={{margin:10,color:'#142b5f'}}>Java Development</li>
                       <li style={{margin:10,color:'#142b5f'}}>AngularJS Development</li>
                       <li style={{margin:10,color:'#142b5f'}}>NodeJS Development</li>
                       <li style={{margin:10,color:'#142b5f'}}>ReactJS Development</li>
                       </ul>

                        </div>






                        <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>In our experience in designing and managing Web sites, we find that this combination of unique expertise & intellectuals in these critical areas aspires for a rich and robust Web experience that stays a mesmerizing impact on the mind of the exposed net users. Current applications and technology that advances, lay a framework that is flexible, suitable to the users and adapts to change which reduces your costs and improves the user’s experience and perception about your business.</p>

                        <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Gwalax, a renowned web development company that offers the best offshore services in UK, USA, and other parts across the world. If you are looking for experienced designers and Web developers, give us a call and shake your hands with us to supersede your expectations because for us our clients are not simply clients but we are their business partners. That is why we always acclaim that where technology becomes art and science, contact us for portfolio examples.</p>

                        <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}></p>


                </div>

                

            </div>

        </div>
    )
}

export default Webdevlopmm