import React, { useRef, useState } from 'react'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Divider from '@mui/material/Divider';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import emailjs from '@emailjs/browser';
import { Alert, Snackbar } from '@mui/material';

const Contact = ({ sidebar }) => {

	const [open, setopen] = useState(false)
	const [open1, setopen1] = useState(false)
	const form = useRef();
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setopen(false);
		setopen1(false);
	};

	const [dta, setdta] = useState({
		fullname: '',
		emailid: '',
		phone: '',
		message: '',
	})

	const { fullname, emailid, phone, message } = dta

	const handlefmchnage = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setdta({ ...dta, [name]: value })
	}



	const sendEmail = (e) => {
		e.preventDefault();


		if (fullname !== '' && emailid !== "" && phone !== '' && message !== "") {


			if (phone.length == 10) {
				emailjs.sendForm('service_pf9pib6', 'template_8bavwgo', form.current, 'UEQHDllkoS2pYPFow')
					.then((result) => {
						setopen(true);
					}, (error) => {
						setopen1(true);
					});

					setdta({fullname:'',emailid:'',message:'',phone:''})
			} else {
				alert('Please Fill 10 Digit Mobile Number')
			}


		} else {
			alert('Please Fill All Data')
		}





	};

	return (
		<div className="container">
			<div className="contact-box" style={{ display: sidebar ? 'grid' : 'flex' }}>
				{sidebar ? (<div className="left">

					<div className='numboxx'>
						<p> <EmailOutlinedIcon style={{ margin: '0px 2px', fontSize: 25, color: '#d1a41b' }} /> <Divider flexItem sx={{ width: 2, margin: '0px 5px' }} color='white' orientation='vertical' /> sales@gwalax.in</p>
					</div>

					<div className='numboxx'>
						<p> <CallOutlinedIcon style={{ margin: '0px 2px', fontSize: 25, color: '#d1a41b' }} /> <Divider flexItem sx={{ width: 2, margin: '0px 5px' }} color='white' orientation='vertical' /> +917898620456</p>
					</div>

					<div className='numboxx'>
						<p> <ContactsOutlinedIcon style={{ margin: '0px 2px', fontSize: 25, color: '#d1a41b' }} /> <Divider flexItem sx={{ width: 2, margin: '0px 5px' }} color='white' orientation='vertical' /> gwalax_team</p>
					</div>



				</div>) : null}
				<div >
					<h2>Contact Us</h2>
					<form ref={form} className="right" onSubmit={sendEmail}>
						<input type="text" className="field" value={dta.fullname} onChange={handlefmchnage} name='fullname' placeholder="Your Name" />
						<input type="email" className="field" value={dta.emailid} onChange={handlefmchnage} name='emailid' placeholder="Your Email" />
						<input type="number" className="field" value={dta.phone} onChange={handlefmchnage} name='phone' placeholder="Phone" />
						<textarea placeholder="Message" value={dta.message} onChange={handlefmchnage} name='message' className="field"></textarea>
						<button className='btn10'>Send</button>

						{/* <input className='btn10' type='button' value='Send'/> */}
					</form>
				</div>
			</div>

			<Snackbar open={open} sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success" sx={{ width: '100%', fontSize: 20 }}>
					Message Sent Successfully Our team will connect you soon...
				</Alert>
			</Snackbar>

			<Snackbar open={open1} sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error" sx={{ width: '100%', fontSize: 20 }}>
					Message Not Sent Successfully some Technical Issue!
				</Alert>
			</Snackbar>
		</div>



	)
}

export default Contact