import { useMediaQuery } from '@mui/material';
import React from 'react'
import Contactcardd from './Contactcardd';
import Maincontact from './Maincontact';


const Contactuspage = () => {
    const matches = useMediaQuery('(max-width:600px)');
    
    const myarr =[
        {name:'63 Jalalpur Gwalior MP 474005 ',icon:'fa-solid fa-location-dot'},
        {name:'+917898620456',icon:'fa fa-phone'},
        {name:'sales@gwalax.in',icon:'fa-solid fa-envelope'},
    ]
    return (
        <div style={{ margin: '30px 15px' }}>

            <div style={{ fontSize: 18 }}>

                <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Talk to us for your Website Designing, Development, Promotion and anything in technology.
                    We’d love to share our expertise with you.</p>

                <p style={{ margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f', textTransform: 'none' }}>Fill in the below form to get along with us! It becomes a lot more amazing when we get together.</p>


            </div>

            <div style={{display:matches?'block':'flex',width:'100%',justifyContent:'space-around',margin:'55px 5px'}}>

            {myarr.map((val,i)=>(
                <Contactcardd key={i} name={val.name} icon={val.icon}/>
            ))}

            </div>

            <div  style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{width:matches?'90%':'50%',textAlign:'center' ,margin: matches ? '30px 0px' : '30px 60px', color: '#142b5f',fontSize:18, textTransform: 'none' }}>Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within a matter of hours to help you.</p>
            
            </div>


            <div >
           

            <Maincontact heading={false} sidebar={false}/>

            </div>




        </div>
    )
}

export default Contactuspage