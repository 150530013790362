import React from 'react'
import Pageheader from '../Components/Pageheader'
import UseTechnologies from '../Components/UseTechnologies'

const Technologies = () => {
  return (
    <div>
        <Pageheader name='Technologies'/>
        <UseTechnologies heading={false}/>
    </div>
  )
}

export default Technologies