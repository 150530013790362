import React from 'react'
import Feuture from '../Components/Feuture'
import Pageheader from '../Components/Pageheader'

const Services = () => {
  return (
    <div>
        <Pageheader name='Services'/>
        <Feuture heading={false} />
    </div>
  )
}

export default Services