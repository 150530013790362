import React, { useState } from 'react'

const Technolybox = ({img,title}) => {
    const [hover , sethover] = useState(false)

    
  return (
    <div style={hover?{width:'12rem',height:'12rem',backgroundImage:'linear-gradient(#663696, #1abaff)',color:'white'}:{width:'10rem',height:'10rem'}}  className="boxtech " onMouseOver={()=>sethover(true)} onMouseLeave={()=>sethover(false)}>
            <img style={{height:hover?70:50}} src={img} alt={img}/>
            <p>{title} </p>
            
        </div>
  )
}

export default Technolybox