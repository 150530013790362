import React from 'react'
import Pageheader from '../Components/Pageheader'
import Webdevlopmm from '../Components/Webdevlopmm'

const WebDevelopment = () => {

  const myarr = [
    {
      name:'Web Devlopment',
      ftitle:'Ahomtech is a leading web development company based out in Gurgaon, India. Over the past years, we have offered all kinds of custom build websites, web portals, and web applications. We use cutting edge technologies to transform the ideas into reality.',
      img1:'https://www.ahomtech.com/wp-content/uploads/2019/06/Web_Developmen_t.jpg',
      ftitle2:'We offer extreme quality service almost in all the existing platforms such as Magento Web Development, Shopify development, Drupal Web Development, etc.',

      heading:'Our comprehensive Website design & Development process is composed of the following:',
      subtile:[
        'Gather the requirements of the business- ‘What is the Web site going to achieve’.',
        " Draft a plan of implementation for the Site and choose a platform.",
        "Have a separate graphic artist, create the wireframes of the Site.",
        "Have a front-end engineer convert the graphics into HTML5, JavaScript and CSS Code.",
        "Have a software engineer to develop any custom components or business logic modules.",
        "Perform a performance analysis to determine the maximum capacity of the Web site amongst the exposed users.",
        "Continuously monitor the site performance and compare them to business metrics with the ongoing environment.",
      ],
      devlopskill:[
        "PHP Development",
        "Magento Development",
        "Drupal Development",
        "Shopify Development",
        "WordPress Development",
        "Java Development",
        "AngularJS Development",
        "NodeJS Development",
        "ReactJS Development",
      ],
      ftitle3:"In our experience in designing and managing Web sites, we find that this combination of unique expertise & intellectuals in these critical areas aspires for a rich and robust Web experience that stays a mesmerizing impact on the mind of the exposed net users. Current applications and technology that advances, lay a framework that is flexible, suitable to the users and adapts to change which reduces your costs and improves the user’s experience and perception about your business.",
      ftitle4:'Ahom Technologies, a renowned web development company that offers the best offshore services in UK, USA, and other parts across the world. If you are looking for experienced designers and Web developers, give us a call and shake your hands with us to supersede your expectations because for us our clients are not simply clients but we are their business partners. That is why we always acclaim that where technology becomes art and science, contact us for portfolio examples.',


    }
  ]
  return (
    <div>
      <Pageheader name='Web Development'/>
      <Webdevlopmm data={myarr}/>
    </div>
  )
}

export default WebDevelopment