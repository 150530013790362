import React from 'react'
import Pageheader from '../Components/Pageheader'
import Uxdesignmm from '../Components/Uxdesignmm'

const Uxdesign = () => {
  return (
    <div>
        <Pageheader name='UI / ux Design'/>
        <Uxdesignmm/>
    </div>
  )
}

export default Uxdesign